import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <h1>404</h1>
    <p>Sorry, you've arrived at an invalid route. Please check that you entered the correct url.</p>
  </Layout>
);

export default NotFoundPage;
